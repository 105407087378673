<template>
  <div>
    <!-- Application Chat -->
    <b-modal :no-close-on-backdrop="true" ref="applicationChatModal" @hidden="onApplicationModalClose" hide-footer>
      <div class="AliifChat-chatbox">
        <div class="chat-messages">
          <b-row v-for="(c, index) in applicationChat" :key="'ac_' + index">
            <div class="message ml-2 mb-2">
              <div v-if="
                $store.getters['user/getUserDetails'].user_id ===
                c.sender_user_id
              " class="user-info">
                <span class="user-name"> You </span>
              </div>
              <div v-else class="user-info">
                <span class="user-name">
                  {{ `${c.user_firstname} ${c.user_lastname}` }}
                </span>
                <span class="user-type">
                  {{ c.user_type_tag || c.user_type_name }}
                </span>
              </div>
              <div class="message-text">
                <span class="message-content">{{ c.message }}</span>
                <sub class="message-timestamp">{{
                  getDateTimeFormatted(c.created_at)
                }}</sub>
              </div>
            </div>
          </b-row>
        </div>
        <div class="chat-input">
          <b-row class="mb-3">
            <b-col cols="9">
              <b-form-textarea type="textarea" @keypress="(e) => {
                if (e.key === 'Enter') {
                  sendApplicationMessage();
                  e.preventDefault();
                }
              }
                " v-model="chatMessage" />
            </b-col>
            <b-col cols="3">
              <button @click="sendApplicationMessage" class="send-button">
                SEND
              </button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>

    <!-- Details Modal -->
    <b-modal :no-close-on-backdrop="true" ref="detailModal" hide-footer size="xl">
      <div class="custom-modal-content">
        <div class="row">
          <!-- GENERAL ELIGIBILITY -->
          <div class="mb-2 col-12 col-md-4">
            <div class="course-detail__sm-title mb-1">General Eligibility</div>
            <div>
              {{
                popupData && popupData.general_eligibility
                  ? popupData.general_eligibility
                  : "NA"
              }}
            </div>
          </div>
          <!-- LANGUAGE REQUIREMENT -->
          <div class="mb-2 col-12 col-md-4">
            <div class="course-detail__sm-title mb-1">Language Requirement</div>
            <!-- <div>{{ languages_requirement }}</div> -->
            <div>
              {{
                popupData && popupData.languages_requirement
                  ? popupData.languages_requirement
                  : "NA"
              }}
            </div>
          </div>
          <!-- ADDITIONAL INFORMATION -->

          <!-- INTAKES -->
          <div class="mb-2 mb-md-0 col-12 col-md-4">
            <div class="course-detail__sm-title mb-1">Intake</div>
            <div>
              <span class="mr-1">Intake Start:</span>
              <span>{{
                popupData && popupData.intake ? popupData.intake : "NA"
              }}</span>
            </div>
            <div class="my-2">
              <div>
                <span class="mr-1">Intake Start:</span><span>{{
                  popupData && popupData.intake_start
                    ? popupData.intake_start
                    : "NA"
                }}</span>
              </div>
              <div>
                <span class="mr-1">Intake End:</span><span>{{
                  popupData && popupData.intake_end
                    ? popupData.intake_end
                    : "NA"
                }}</span>
              </div>
            </div>

            <div>
              <span class="mr-1">Intake Deadline 1:</span><span>{{
                popupData && popupData.intake_deadline_1
                  ? popupData.intake_deadline_1
                  : "NA"
              }}</span>
            </div>
            <div>
              <span class="mr-1">Intake Deadline 2:</span><span>{{
                popupData && popupData.intake_deadline_2
                  ? popupData.intake_deadline_2
                  : "NA"
              }}</span>
            </div>
            <div>
              <span class="mr-1">Intake Deadline 3:</span><span>{{
                popupData && popupData.intake_deadline_3
                  ? popupData.intake_deadline_3
                  : "NA"
              }}</span>
            </div>
            <div>
              <span class="mr-1">Intake Deadline 4:</span><span>{{
                popupData && popupData.intake_deadline_4
                  ? popupData.intake_deadline_4
                  : "NA"
              }}</span>
            </div>
            <div>
              <span class="mr-1">Intake Deadline 5:</span><span>{{
                popupData && popupData.intake_deadline_5
                  ? popupData.intake_deadline_5
                  : "NA"
              }}</span>
            </div>
            <div>
              <span class="mr-1">Intake Deadline 6:</span><span>{{
                popupData && popupData.intake_deadline_6
                  ? popupData.intake_deadline_6
                  : "NA"
              }}</span>
            </div>
          </div>
          <!-- INTERNATIONAL SCHOLARSHIP DESCRIPTION -->
          <div class="col-12 col-md-4">
            <div class="course-detail__sm-title mb-1">
              International Scholarship Description
            </div>
            <!-- <div><p>{{ internation_scholarship_description }}</p></div> -->
            <div>
              <p>
                {{
                  popupData && popupData.internation_scholarship_description
                    ? popupData.internation_scholarship_description
                    : "NA"
                }}
              </p>
            </div>

            <div class="fs-14">
              <span class="font-bold mr-1">Scholarship Page Link:</span><a href="">{{
                popupData && popupData.scholarship_page_link
                  ? popupData.scholarship_page_link
                  : "NA"
              }}</a>
            </div>
          </div>
          <!-- GRE -->
          <div class="mb-2 col-12 col-md-4">
            <div class="course-detail__sm-title mb-1">GRE</div>

            <div>
              <span class="mr-1">GRE Requirement:</span><span>{{
                popupData && popupData.gre_requirement
                  ? popupData.gre_requirement
                  : "NA"
              }}</span>
            </div>
            <div>
              <span class="mr-1">GRE Score:</span><span>{{
                popupData && popupData.gre_score ? popupData.gre_score : "NA"
              }}</span>
            </div>
            <div>
              <span class="mr-1">SAT Requirement:</span><span>{{
                popupData && popupData.sat_requirement
                  ? popupData.sat_requirement
                  : "NA"
              }}</span>
            </div>
            <div>
              <span class="mr-1">SAT Score:</span><span>{{
                popupData && popupData.sat_score ? popupData.sat_score : "NA"
              }}</span>
            </div>
          </div>
          <!-- Additional -->
          <div class="col-12 col-md-4">
            <div class="course-detail__sm-title mb-1">
              Additional Information
            </div>
            <!-- <div><p>{{ internation_scholarship_description }}</p></div> -->

            <div class="fs-14">
              <span class="font-bold mr-1">Stem Course:</span><span>{{
                popupData && popupData.stem_course
                  ? popupData.stem_course
                  : "NA"
              }}</span>
            </div>
            <div class="fs-14">
              <span class="font-bold mr-1">Fifteen Years Education Acceptable:</span><span>{{
                popupData && popupData.fifteen_years_education_acceptable
                  ? popupData.fifteen_years_education_acceptable
                  : "NA"
              }}</span>
            </div>
            <div class="fs-14">
              <span class="font-bold mr-1">IELTS Waiver:</span><span>{{
                popupData && popupData.ielts_waiver
                  ? popupData.ielts_waiver
                  : "NA"
              }}</span>
            </div>
            <div class="fs-14">
              {{
                popupData && popupData.if_ielts_is_waived_conditions_for
                  ? popupData.if_ielts_is_waived_conditions_for
                  : "NA"
              }}
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Cancel Application Modal -->
    <b-modal :no-close-on-backdrop="true" id="cancel-application-modal" ref="cancel-application-modal" centered title="Cancel Application"
      ok-title="Confirm" cancel-title="Back" @hidden="resetCancelApplication" @ok="cancelApplication">
      {{ application_to_cancel ? application_to_cancel.university_name : "" }}
      <br />
      {{ application_to_cancel ? application_to_cancel.course_name : "" }}
    </b-modal>

    <!-- University Payment Status Modal -->
    <b-modal :no-close-on-backdrop="true" v-model="showPaymentModal" id="payment-status" no-close-on-esc no-close-on-backdrop no-close
      ref="ref-payment-status" centered title="Payment Status" hideHeaderClose hide-footer @ok="reloadPageWithoutParam">
      <section class="pb-2">
        <div v-if="payment_status === 'pending' || payment_status === 'ongoing'">
          <h3>Payment Pending</h3>
          <p>You're almost there! please head over to the Flywire portal to complete your
            payment, and you'll be all set in no time.</p>
        </div>
        <!-- <div v-if="payment_status === 'ongoing'">
          <h3>Ongoing Payment</h3>
          <p>Thank you for confirming your details! You're just one step away from completing your payment. Please head
            over to the Flywire portal to finalize everything. Once you're done, you'll be good to go.</p>
        </div> -->
        <div v-if="payment_status === 'guaranteed'">
          <h3>Payment is Processed!</h3>
          <p>
            Thank you for completing your payment! Flywire has received your funds and is now processing the transfer to
            your university. Your funds will be transfered to the university shortly.
          </p>
        </div>
        <div v-if="payment_status === 'success'">
          <h3>Payment Successfully!</h3>
          <p>Thank you for completing your payment.</p>
        </div>
        <div v-if="payment_status === 'failed'">
          <h3>Payment Failed.</h3>
          <p>Your payment could not be completed at this time, please try again.</p>
        </div>

        <div class="d-flex justify-content-end">
          <b-button variant="primary" class="mt-2" @click="reloadPageWithoutParam">OK</b-button>
        </div>
      </section>
    </b-modal>

    <!-- University Payment Confirmation Modal -->
    <b-modal :no-close-on-backdrop="true" id="university-payment-confirmation-modal" hide-footer ref="ref-university-payment-confirmation-modal"
      centered title="Confirm University Payment" @hidden="closeUniversityPaymentConfirmationModal">
      <div class="p-1" v-if="this.university_payment">
        <p>Thank you for choosing to make this payment. Once you click the "Pay" button, you will be redirected to the
          <b>Flywire Portal</b> to complete your transaction securely.
        </p>

        <div class="d-flex justify-content-end mt-3">
          <b-button variant="primary" @click="makeUniversityPayment">Pay {{
            getSymbolFromCurrency(this.university_payment.currency) }}{{
              this.university_payment.amount }}</b-button>
        </div>
      </div>
    </b-modal>

    <!-- Applications and Favourite tab -->
    <b-tabs>
      <b-tab title="Applications & Favourites">
        <b-card title="My Applications" class="overflow-x-scroll">
          <table class="table overflow-x-scroll w-100 responsive" responsive>
            <thead>
              <tr>
                <th v-for="item in studentApplicationsColumns" :key="item.key">
                  {{ item.label }}
                </th>
              </tr>
            </thead>
            <draggable :class="drag === true ? 'cursor-grabbing' : ''" v-model="studentApplications"
              group="studentApplications" @start="() => {
                drag = true;
              }
                " @end="onPrioritySelect" tag="tbody">
              <tr v-for="(item, index) in studentApplications" :key="item.id" class="item-row">
                <td>
                  {{ index + 1 }}
                </td>
                <td>{{ item.country_name }}</td>
                <td>
                  <div style="width: max-content">
                    {{ item.university_name }}
                  </div>
                </td>
                <td>
                  <template>
                    <div style="width: max-content">
                      {{ item.course_name }}
                    </div>
                    <div class="d-flex align-items-center">
                      <!-- External Link Icon -->
                      <a :href="item.additional_information_link" style="margin-right: 5px; margin-left: 10px"
                        target="_blank">
                        <b-avatar variant="light-danger" size="30">
                          <feather-icon icon="ExternalLinkIcon" size="15" class="text-danger" />
                        </b-avatar>
                      </a>
                      <div>
                        <a @click="() => openDetailModal(item)" style="margin-right: 3px">
                          <b-avatar variant="light-danger" size="30">
                            <feather-icon icon="InfoIcon" size="15" class="text-danger" /> </b-avatar></a>
                      </div>
                    </div>
                    <div class="">
                      <div class="d-flex mt-1">
                        <span class="badge badge-secondary mr-1" v-if="item.gre_requirement === 'Y'" style="
                            color: #2e9379;
                            background-color: #00d09c;
                            border-radius: 9999px;
                          ">GRE Required</span>
                        <span class="badge badge-secondary mr-1" v-if="
                          item.gre_score !== null && !isNaN(item.gre_score)
                        " style="
                            color: #2e9379;
                            background-color: #00d09c;
                            border-radius: 9999px;
                          ">
                          {{ item.gre_score }}
                        </span>
                        <span class="badge badge-secondary mr-1" v-if="item.fifteen_years_education_acceptable === 'Y'"
                          style="
                            color: #2e9379;
                            background-color: #00d09c;
                            border-radius: 9999px;
                          ">15 years accepted</span>
                        <span class="badge badge-secondary mr-1" v-if="item.ielts_waiver === 'Y'" style="
                            color: #2e9379;
                            background-color: #00d09c;
                            border-radius: 9999px;
                          ">IELTS Waiver</span>
                        <span class="badge badge-secondary mr-1" v-if="item.stem_course === 'Y'" style="
                            color: #2e9379;
                            background-color: #00d09c;
                            border-radius: 9999px;
                          ">STEM</span>
                        <span class="badge badge-secondary mr-1" v-if="
                          item.sat_score !== null && !isNaN(item.sat_score)
                        " style="
                            color: #2e9379;
                            background-color: #00d09c;
                            border-radius: 9999px;
                          ">{{ item.sat_score }}</span>
                      </div>
                    </div>
                  </template>
                </td>
                <td>{{ item.course_year }} Year(s)</td>
                <td>
                  <template>
                    <div style="width: max-content">
                      {{ formatIntake(item.intake) }}
                    </div>
                  </template>
                </td>
                <td>{{ item.application_fee }}</td>
                <td>
                  <template>
                    <div style="width: max-content">
                      {{ item.course_fee_pa.toLocaleString("en-IN") }}
                      {{ item.currency }}
                    </div>
                  </template>
                </td>
                <td>
                  {{ item.intake_month }}
                </td>
                <td>
                  {{ item.intake_year }}
                </td>

                <td>
                  <div>
                    <feather-icon :badge="item.unread_chat_count" style="cursor: pointer" icon="MessageSquareIcon"
                      size="20" @click="showApplicationChatModal(item.sa_mapper_id)" />
                  </div>
                </td>
                <td>{{ item.status_name }}</td>
                <td>{{ item.sa_campus_locations }}</td>
                <td>
                  <div role="button" class="text-center" @click="(e) => {
                    setApplicationToRemove(item);
                  }
                    ">
                    <b-avatar variant="light-danger" size="30">
                      <feather-icon icon="TrashIcon" size="15" class="text-danger" />
                    </b-avatar>
                  </div>
                </td>
              </tr>
            </draggable>
          </table>
        </b-card>

        <!-- Favourites Table Card -->
        <b-card title="My Favourites">
          <b-table ref="refStudentFavouritesTable" responsive :fields="studentFavouritesColumns" primary-key="id"
            show-empty :items="studentFavourites" empty-text="No matching records found" class="position-relative">
            <template #cell(No)="data">
              {{ studentFavourites.indexOf(data.item) + 1 }}
            </template>
            <template #cell(course_fee_pa)="data">
              <div style="width: max-content">
                {{ data.value.toLocaleString("en-IN") }}
                {{ data.item.currency }}
              </div>
            </template>
            <template #cell(course_year)="data">
              <div style="width: max-content">
                <div v-if="data.value">{{ data.value }} Year(s)</div>
                <div v-if="data.item.course_month" style="margin-top: 10px">
                  {{ data.item.course_month }} Month(s)
                </div>
              </div>
            </template>
            <template #cell(intake)="data">
              <div style="width: max-content">
                {{ formatIntake(data.value) }}
              </div>
            </template>
            <template #cell(course_name)="data">
              <div style="width: max-content">
                {{ data.value }}
              </div>
              <div class="d-flex align-items-center">
                <!-- External Link Icon -->
                <a :href="data.item.additional_information_link" style="margin-right: 5px; margin-left: 10px"
                  target="_blank">
                  <b-avatar variant="light-danger" size="30">
                    <feather-icon icon="ExternalLinkIcon" size="15" class="text-danger" />
                  </b-avatar>
                </a>
                <div>
                  <a @click="() => openDetailModal(data.item)" style="margin-right: 3px">
                    <b-avatar variant="light-danger" size="30">
                      <feather-icon icon="InfoIcon" size="15" class="text-danger" /> </b-avatar></a>
                </div>
              </div>
              <div class="">
                <div class="d-flex mt-1">
                  <span class="badge badge-secondary mr-1" v-if="data.item.gre_requirement === 'Y'" style="
                      color: #2e9379;
                      background-color: #00d09c;
                      border-radius: 9999px;
                    ">GRE Required</span>
                  <span class="badge badge-secondary mr-1" v-if="
                    data.item.gre_score !== null &&
                    !isNaN(data.item.gre_score)
                  " style="
                      color: #2e9379;
                      background-color: #00d09c;
                      border-radius: 9999px;
                    ">
                    {{ data.item.gre_score }}
                  </span>
                  <span class="badge badge-secondary mr-1" v-if="data.item.fifteen_years_education_acceptable === 'Y'"
                    style="
                      color: #2e9379;
                      background-color: #00d09c;
                      border-radius: 9999px;
                    ">15 years accepted</span>
                  <span class="badge badge-secondary mr-1" v-if="data.item.ielts_waiver === 'Y'" style="
                      color: #2e9379;
                      background-color: #00d09c;
                      border-radius: 9999px;
                    ">IELTS Waiver</span>
                  <span class="badge badge-secondary mr-1" v-if="data.item.stem_course === 'Y'" style="
                      color: #2e9379;
                      background-color: #00d09c;
                      border-radius: 9999px;
                    ">STEM</span>
                  <span class="badge badge-secondary mr-1" v-if="
                    data.item.sat_score !== null &&
                    !isNaN(data.item.sat_score)
                  " style="
                      color: #2e9379;
                      background-color: #00d09c;
                      border-radius: 9999px;
                    ">{{ data.item.sat_score }}</span>
                </div>
              </div>
            </template>

            <template #cell(apply)="data">
              <div class="d-flex">
                <b-dropdown id="actions-dropdown" text="Actions" variant="outline-primary" size="sm">
                  <b-dropdown-item @click="(e) => {
                    applyToCourse(data.item.course_id);
                  }
                    ">
                    Apply
                  </b-dropdown-item>
                  <b-dropdown-item @click="(e) => {
                    removeFromFavourite(data.item.course_id);
                  }
                    ">
                    Remove from Favourites
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>

            <template #cell(remove)="data">
              <div role="button" @click="(e) => {
                removeFromFavourite(data.item.course_id);
              }
                ">
                <b-avatar variant="light-danger" size="30">
                  <feather-icon icon="TrashIcon" size="15" class="text-danger" />
                </b-avatar>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-tab>

      <!-- Counselor Recommendation Tab -->
      <b-tab title="Aliff Recommendations">
        <b-card title="Aliff Recommendations">
          <b-table ref="refCounselorSuggestionsTable" responsive :fields="recommendationsColumns" primary-key="id"
            show-empty :items="courseRecommendations" empty-text="No matching records found" class="position-relative">
            <template #cell(No)="data">
              {{ courseRecommendations.indexOf(data.item) + 1 }}
            </template>

            <template #cell(course_name)="data">
              <div style="width: max-content">
                {{ data.value }}
              </div>
              <div class="d-flex align-items-center">
                <!-- {{ data.value }} -->
                <!-- External Link Icon -->
                <a :href="data.item.additional_information_link" style="margin-right: 5px; margin-left: 10px"
                  target="_blank">
                  <b-avatar variant="light-danger" size="30">
                    <feather-icon icon="ExternalLinkIcon" size="15" class="text-danger" />
                  </b-avatar>
                </a>
                <div>
                  <a @click="() => openDetailModal(data.item)" style="margin-right: 3px">
                    <b-avatar variant="light-danger" size="30">
                      <feather-icon icon="InfoIcon" size="15" class="text-danger" /> </b-avatar></a>
                </div>
              </div>
              <div class="">
                <div class="d-flex mt-1">
                  <span class="badge badge-secondary mr-1" v-if="data.item.gre_requirement === 'Y'" style="
                      color: #2e9379;
                      background-color: #00d09c;
                      border-radius: 9999px;
                    ">GRE Required</span>
                  <span class="badge badge-secondary mr-1" v-if="
                    data.item.gre_score !== null &&
                    !isNaN(data.item.gre_score)
                  " style="
                      color: #2e9379;
                      background-color: #00d09c;
                      border-radius: 9999px;
                    ">
                    {{ data.item.gre_score }}
                  </span>
                  <span class="badge badge-secondary mr-1" v-if="data.item.fifteen_years_education_acceptable === 'Y'"
                    style="
                      color: #2e9379;
                      background-color: #00d09c;
                      border-radius: 9999px;
                    ">15 years accepted</span>
                  <span class="badge badge-secondary mr-1" v-if="data.item.ielts_waiver === 'Y'" style="
                      color: #2e9379;
                      background-color: #00d09c;
                      border-radius: 9999px;
                    ">IELTS Waiver</span>
                  <span class="badge badge-secondary mr-1" v-if="data.item.stem_course === 'Y'" style="
                      color: #2e9379;
                      background-color: #00d09c;
                      border-radius: 9999px;
                    ">Stem Course</span>
                  <span class="badge badge-secondary mr-1" v-if="
                    data.item.sat_score !== null &&
                    !isNaN(data.item.sat_score)
                  " style="
                      color: #2e9379;
                      background-color: #00d09c;
                      border-radius: 9999px;
                    ">{{ data.item.sat_score }}</span>
                </div>
              </div>
            </template>
            <template #cell(course_fee_pa)="data">
              <div style="width: max-content">
                {{ data.value.toLocaleString("en-IN") }}
                {{ data.item.currency }}
                <sub>(P.A.)</sub>
              </div>
            </template>
            <template #cell(course_year)="data">
              <div style="width: max-content">
                <div v-if="data.value">{{ data.value }} Year(s)</div>
                <div v-if="data.item.course_month" style="margin-top: 10px">
                  {{ data.item.course_month }} Month(s)
                </div>
              </div>
            </template>

            <template #cell(apply)="data">
              <b-button @click="(e) => {
                acceptSuggetion(data.item.course_id);
              }
                ">
                Apply
              </b-button>
            </template>

            <template #cell(reject)="data">
              <div role="button" @click="(e) => {
                rejectSuggestion(data.item.course_id);
              }
                ">
                <b-avatar variant="light-danger" size="30">
                  <feather-icon icon="TrashIcon" size="15" class="text-danger" />
                </b-avatar>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-tab>
      <!-- 
      <b-tab title="University Payments">
        <b-card>
          <b-table id="StudentsApplicationsTableId" ref="refStudentsApplicationsTable" class="border rounded-lg"
            :items="university_payments" responsive :fields="universityPaymentTableColumns" primary-key="sa_mapper_id"
            show-empty empty-text="No matching records found">

            <template #cell(No)="data">
              {{ university_payments.indexOf(data.item) + 1 }}
            </template>

            <template #cell(fee_type)="data">
              {{ fee_type_map[data.item.fee_type] }}
            </template>

            <template #cell(label)="data">
              <div style="width: max-content">
                <p :style="data.item.is_active === 'N' &&
                  'text-decoration: line-through'
                  ">
                  {{ data.item.label }}
                </p>
              </div>
            </template>

            <template #cell(university_name)="data">
              <div style="width: max-content">
                <p :style="data.item.is_active === 'N' &&
                  'text-decoration: line-through'
                  ">
                  {{ data.item.university_name }}
                </p>
              </div>
            </template>

            <template #cell(course_name)="data">
              <div style="width: max-content">
                <p :style="data.item.is_active === 'N' &&
                  'text-decoration: line-through'
                  ">
                  {{ data.item.course_name }}
                </p>
              </div>
            </template>

            <template #cell(fw_university_name)="data">
              <div style="width: max-content">
                <p :style="data.item.is_active === 'N' &&
                  'text-decoration: line-through'
                  ">
                  {{ data.item.fw_university_name }}
                </p>
              </div>
            </template>

            <template #cell(currency)="data">
              <div style="width: max-content">
                <p :style="data.item.is_active === 'N' &&
                  'text-decoration: line-through'
                  ">
                  {{ data.item.currency }}
                </p>
              </div>
            </template>

            <template #cell(amount)="data">
              <div style="width: max-content">
                <p :style="data.item.is_active === 'N' &&
                  'text-decoration: line-through'
                  ">
                  {{ data.item.amount }}
                </p>
              </div>
            </template>

            <template #cell(payment_status)="data">
              <div style="width: max-content">
                <b-badge pill :variant="payment_status_map[data.item.payment_status].variant
                  "
                  v-if="data.item.is_active === 'Y' && (data.item.payment_status !== 'pending' && data.item.payment_status !== 'failed')">
                  {{ payment_status_map[data.item.payment_status].label }}
                </b-badge>

                <b-button variant="primary" size="sm" v-else
                  @click="() => openUniversityPaymentConfirmationModal(data.item.up_id)">Pay
                  Now</b-button>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-tab> -->
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BTooltip,
  BTabs,
  BTab,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BFormSpinbutton,
} from "bootstrap-vue";

import vSelect from "vue-select";
import StudentServices from "@/apiServices/StudentServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import helpers from "@/libs/helpers";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import draggable from "vuedraggable";
import { TokenService } from "@/apiServices/storageService";
import { BASEURL } from "@/config";
import getSymbolFromCurrency from "currency-symbol-map";

export default {
  components: {
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BTabs,
    BTab,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BFormSpinbutton,
    FeatherIcon,
    draggable,
  },

  data() {
    return {
      popupData: null,
      sa_mapper_id: null,
      chatMessage: "",
      applicationChat: [],
      getApplicationChatInterval: null,
      university_payments: [],
      university_payment: null,

      payment_status_map: {
        pending: {
          variant: "primary",
          label: "Pending",
        },
        ongoing: {
          variant: "secondary",
          label: "Ongoing",
        },
        success: {
          variant: "success",
          label: "Success",
        },
        failed: {
          variant: "Failed",
          label: "danger",
        },
        guaranteed: {
          variant: "success",
          label: "Processing"
        }

      },

      application_to_cancel: null,
      studentApplications: [],
      studentFavourites: [],
      courseRecommendations: [],
      searchQuery: "",
      drag: false,
      mydata: [
        { id: 1, name: "Abby", sport: "basketball" },
        { id: 2, name: "Brooke", sport: "soccer" },
        { id: 3, name: "Courtenay", sport: "volleyball" },
        { id: 4, name: "David", sport: "rugby" },
      ],
      studentApplicationsColumns: [
        {
          key: "No",
          label: "Priority",
          stickyColumn: true,
          variant: "primary",
        },
        { key: "country_name", label: "Country" },
        { key: "university_name", label: "University" },
        { key: "course_name", label: "Course" },

        { key: "course_year", label: "Duration" },
        { key: "intake", label: "Intake Months" },
        { key: "application_fee", label: "Application Fee" },
        { key: "course_fee_pa", label: "Course Fee (P.A.)" },
        { key: "intake_month", label: "Intake Month" },
        { key: "intake_year", label: "Intake Year" },

        { key: "application_chat", label: "Chat" },
        { key: "status_name", label: "Status" },
        { key: "sa_campus_locations", label: "Campus Locations" },
        { key: "actions", label: "Actions" },
      ],

      studentFavouritesColumns: [
        { key: "No", label: "#" },
        { key: "country_name", label: "Country" },
        { key: "university_name", label: "University" },
        { key: "course_name", label: "Course" },

        { key: "course_year", label: "Duration" },

        { key: "intake", label: "Intake Months" },
        { key: "course_fee_pa", label: "Fee (P.A.)" },
        { key: "apply", label: "Actions" },
        // { key: "remove" },
      ],
      recommendationsColumns: [
        { key: "No", label: "Priority" },
        { key: "country_name", label: "Country" },
        { key: "university_name", label: "University" },
        { key: "course_name", label: "Course" },
        { key: "course_year", label: "Duration" },

        { key: "intake", label: "Intake Months" },
        { key: "course_fee_pa", label: "Fee (P.A.)" },
        { key: "apply" },
        { key: "reject" },
      ],

      universityPaymentTableColumns: [
        { key: "No", label: "#" },
        { key: "fee_type", label: "Fee Type" },
        { key: "university_name", label: "University" },
        { key: "course_name", label: "Course" },
        { key: "currency", label: "Currency" },
        { key: "amount", label: "Amount" },
        { key: "label", label: "Remark" },
        { key: "payment_status", label: "Payment", stickyColumn: true },
      ],

      showPaymentModal: false,
      payment_status: "pending",
      fee_type_map: {
        deposit: "Deposit",
        advance_fee: "Advance Fee",
        application_fee: "Application Fee",
        other: "Other",
      }
    };


  },
  computed: {
    appPriorityOptions() {
      if (this.studentApplications.length) {
        return this.studentApplications.map((i, index) => index + 1);
      }
      return [];
    },
  },
  methods: {

    reloadPageWithoutParam() {
      window.location.replace(
        `${window.location.origin}${window.location.pathname}`
      );
    },

    getSymbolFromCurrency,
    onDragEnd(event) {
      const newOrder = event.newIndex;
      // Handle the reordering logic here
      console.log("New order:", newOrder);
    },

    async getAllUniversityPayments() {
      try {
        const res = await StudentServices.getAllUniversityPayments();
        if (res.data.status) {
          this.university_payments = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },

    openUniversityPaymentConfirmationModal(up_id) {
      this.university_payment = this.university_payments.find(up => up.up_id === up_id)
      this.$nextTick(() => {
        this.$refs['ref-university-payment-confirmation-modal'].show()
      })

    },

    closeUniversityPaymentConfirmationModal() {
      // this.university_payment = null
      this.$refs['ref-university-payment-confirmation-modal'].hide()
    },

    async makeUniversityPayment() {

      // this.closeUniversityPaymentConfirmationModal()
      this.$refs['ref-university-payment-confirmation-modal'].hide()

      this.showPaymentModal = true

      if (!this.university_payment) return

      try {
        const access_token = TokenService.getToken();

        const url =
          BASEURL +
          `/student/make-university-payment/${this.university_payment.up_id}?authorization=${access_token}`;
        window.open(url, "_blank");

        this.verifyUniversityPayment(this.university_payment.up_id);
      } catch (error) {
        console.error(error);
      }
    },

    verifyUniversityPayment(up_id) {
      this.showPaymentModal = true;

      const interval = setInterval(async () => {
        try {
          let res = await StudentServices.verifyUniversityPayment(up_id);

          if (res.data.status) {
            this.payment_status = res.data.data.payment_status
            if (this.payment_status != "pending" && this.payment_status != "ongoing") {
              clearInterval(interval);
            }
          } else {
            this.payment_status = "failed"
            clearInterval(interval);
          }
        } catch (error) {
          console.error({ error });
          clearInterval(interval);
        }
      }, 4000);
    },

    async onPrioritySelect(toIndex, fromIndex, item) {
      this.$nextTick(async () => {
        await StudentServices.setApplicationsPriority(this.studentApplications);
      });
      this.drag = false;
    },

    onDetailModalClose() {
      this.popupData = {};
      this.$refs.detailModal.hidden();
    },

    openDetailModal(data) {
      // console.log("popupData => ",data);
      this.popupData = data;
      this.$refs.detailModal.show();
    },

    formatIntake: helpers.formatIntake,

    async loadApplicationChat() {
      try {
        const res = await StudentServices.getApplicationChat({
          sa_mapper_id: this.sa_mapper_id,
        });

        this.applicationChat = res.data.data;
      } catch (error) {
        console.error("Error in loadApplicationChat", error);
      }
    },

    async showApplicationChatModal(sa_mapper_id) {
      this.$refs["applicationChatModal"].show();

      this.sa_mapper_id = sa_mapper_id;

      await this.loadApplicationChat();

      this.getApplicationChatInterval = setInterval(
        this.loadApplicationChat,
        5000
      );
    },

    onApplicationModalClose() {
      clearInterval(this.getApplicationChatInterval);
      this.applicationChat = [];
      this.$refs["applicationChatModal"].hide();
    },

    async sendApplicationMessage() {
      await StudentServices.sendApplicationChat({
        sa_mapper_id: this.sa_mapper_id,
        message: this.chatMessage,
      });
      this.chatMessage = "";
      await this.loadApplicationChat();
    },

    setApplicationToRemove(application_to_cancel) {
      this.application_to_cancel = application_to_cancel;

      if (
        this.application_to_cancel.status_id &&
        this.application_to_cancel.status_id > 1
      ) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Cannot Cancel this Application",
            icon: "X",
            variant: "failure",
          },
        });
        return;
      }

      this.$refs["cancel-application-modal"].show();
    },

    resetCancelApplication() {
      this.application_to_cancel = null;
    },

    cancelApplication() {
      const course_id = this.application_to_cancel.course_id;
      StudentServices.cancelApplication({ course_id })
        .then((response) => {
          if (response.data.status) {
            this.getApplications();
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message || "Application canceled",
                icon: "Check",
                variant: "success",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message || "Application Not Canceled",
                icon: "Check",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Error in canceling application",
              icon: "Check",
              variant: "success",
            },
          });
          console.log("Error in canceling application ", err);
        });
      this.application_to_cancel = null;
    },

    applyToCourse(course_id) {
      StudentServices.ApplyCourse({ course_id })
        .then((response) => {
          if (response.data.status) {
            this.getApplications();
            // this.getFavouriteCourses();
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message || "Cannot Apply to course",
                icon: "X",
                variant: "failure",
              },
            });
          }
        })
        .catch((err) => {
          console.log("Error in applying to course ", err);
        });
    },

    removeFromFavourite(course_id) {
      StudentServices.removeCourseFromFavourite({ course_id }).then(
        (response) => {
          if (response.data.status) {
            this.getFavouriteCourses();
          }
        }
      );
    },

    acceptSuggetion(course_id) {
      StudentServices.acceptCourseSuggestion({ course_id })
        .then((response) => {
          if (response.data.status) {
            this.getSuggestedCourses();
            this.getApplications();
          }
        })
        .catch((err) => {
          console.log("Error in accepting course suggestion ", err);
        });
    },

    rejectSuggestion(course_id) {
      StudentServices.rejectCourseSuggestion({ course_id })
        .then((response) => {
          if (response.data.status) {
            this.getSuggestedCourses();
          }
        })
        .catch((err) => {
          console.log("Error in rejecting course suggestion ", err);
        });
    },

    getApplications() {
      StudentServices.getAppliedCourses()
        .then((response) => {
          this.studentApplications = response.data.data;
          this.priorityItemsArray = this.studentApplications;
        })
        .catch((err) => {
          console.log("Error in getting ", err);
        });
    },

    getFavouriteCourses() {
      StudentServices.getFavouriteCourses()
        .then((response) => {
          if (response.data.status) {
            this.studentFavourites = response.data.data;
          }
        })
        .catch((err) => {
          console.log("Error in getting favourite courses ", err);
        });
    },

    getSuggestedCourses() {
      StudentServices.getSuggestedCourses()
        .then((response) => {
          if (response.data.status) {
            this.courseRecommendations = response.data.data;
          }
        })
        .catch((err) => {
          console.log("Error in geting suggested course ", err);
        });
    },

    getDateTimeFormatted: helpers.getDateTimeFormatted,
  },
  beforeMount() {
    this.getApplications();
    this.getFavouriteCourses();
    this.getSuggestedCourses();
    this.getAllUniversityPayments();
  },
};
</script>

<style lang="scss" scoped>
#actions-dropdown .dropdown-toggle::after {
  display: none;
}

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style>
.AliifChat-chatbox {
  width: 460px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  overflow-x: hidden;
}

.chat-header {
  background-color: #00d09c;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  font-weight: bold;
  border-bottom: 2px solid #5367ff;
}

.chat-messages {
  max-height: 500px;
  overflow-y: auto;
  padding: 10px;
  overflow-x: hidden;
}

.message {
  margin-bottom: 10px;
}

.user-info {
  margin-right: 10px;
}

.user-name {
  font-weight: bold;
}

.user-type {
  font-size: 12px;
  color: #777;
}

.message-text {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 8px 12px;
  word-break: break-word;
}

.message-content {
  word-wrap: break-word;
}

.message-timestamp {
  font-size: 7px;
  padding-left: 5px;
  color: #777;
}

.chat-input {
  padding-top: 10px;
  margin-left: 15px;
  margin-right: 15px;

  border-top: 1px solid #5367ff;
}

.send-button {
  background-color: #00d09c;
  color: #5367ff;
  font-weight: bold;
  border: 1px solid #5367ff;
  padding: 8px 12px;
  border-radius: 20px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #5367ff;
  color: #00d09c;
}

/* .per-page-selector {
  width: 90px;
} */
.items {
  width: 77%;
  margin: 0 auto;
  background: black;
  padding: 3rem;
  color: #8de541;
  border-radius: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.table-item {
  margin: 0.5rem;
}

.model-header {
  font-size: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.item-row {
  cursor: pointer;
}

.items pre {
  color: #fff;
  width: 200px;
  margin: auto;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
